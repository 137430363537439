import BaseService from '@landrover/service/base.service'

export default class SAASService extends BaseService {
  constructor() {
    // 初始化行为
    super()
    this.baseUrl = {
      dev: 'https://dev.qujianpan/api',
      gqc: 'https://dev.qujianpan/api',
      prd: 'https://qujianpan/api'
    }
  }

  setup(): Promise<void> {
    // 启动行为
    return super.setup()
  }

  // 业务接口务按照下列方法来书写
  //
  // /**
  //  * 实例接口
  //
  //  * @param {string} exampleParam1
  //  * @param {number} exampleParam2
  //  * @returns {Promise<exampleResponse>}
  //  * @memberof SAASService
  //  */
  // exampleRequest(exampleParam1: string, exampleParam2: number): Promise<exampleResponse> {
  //   return this.request(
  //     'cgi/examplePath',
  //     'GET',
  //     {
  //       exampleParam1,
  //       exampleParam2
  //     }
  //   )
  // }

  /**
   * 获取用户信息
   */
  getUserInfo(): Promise<void> {
    return this.request('/front/user/get_user_info', 'GET', {})
  }
}
