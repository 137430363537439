type AnyObject = string | number | symbol | any
export default class Storage {
  async setItem(
    key: string,
    value:
      | string
      | number
      | {
          [key: string]: AnyObject
        }
  ): Promise<Error> {
    console.log(key, value)
    return new Error('该方法目前不支持')
  }

  setItemSync(key: string, value: string): boolean {
    try {
      window.localStorage.setItem(key, value)
      console.log(`成功设置${key}${value}`)
      return true
    } catch (e) {
      console.error(e)
      console.log(`失败移除${key}`)
      return false
    }
  }

  async getItem(key: string): Promise<Error> {
    console.log(key)
    return new Error('该方法目前不支持')
  }

  getItemSync(key: string): string | null {
    try {
      return window.localStorage.getItem(key)
    } catch (e) {
      console.error(e)
      return null
    }
  }

  removeItem(key: string): Promise<Error> {
    console.log(key)
    return Promise.reject(new Error('该方法目前不支持'))
  }

  removeItemSync(key: string): boolean {
    try {
      window.localStorage.removeItem(key)
      console.log(`成功移除${key}`)
      return true
    } catch (e) {
      console.error(e)
      console.log(`失败移除${key}`)
      return false
    }
  }

  clear(): Promise<Error> {
    return Promise.reject(new Error('该方法目前不支持'))
  }

  clearSync(): boolean {
    try {
      window.localStorage.clear()
      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }
}
