import cloneDeep from 'lodash/cloneDeep'
import request, { Method } from '@qjp/qjp-core-request'
import { config } from '../foundation/index'

declare type ResponsePackFormat = 'legacy' | 'old' | 'new'

declare type BaseUrl = {
  dev: string
  gqc: string
  prd: string
}

type AnyObject = string | number | symbol | any

interface UnknownObject {
  [key: string]: AnyObject
}
interface Response {
  data: { err_msg: string; err_no: number; results: any }
  status: number
  statusText: string
  headers: UnknownObject
}

export default class Service {
  baseUrl: BaseUrl

  responsePackFormat: ResponsePackFormat

  constructor() {
    this.responsePackFormat = 'new'
    this.baseUrl = {
      dev: '',
      gqc: '',
      prd: ''
    }
  }

  setup(): Promise<void> {
    return Promise.resolve()
  }

  url(path: string): string {
    const env = config.env || 'dev'
    return `${this.baseUrl[env]}${path}`
  }

  request<T>(
    path: string,
    method: Method,
    data?: UnknownObject,
    header?: UnknownObject
  ): Promise<T> {
    const defaultHeader = { 'Content-Type': 'application/json' }
    const finalHeader = Object.assign(defaultHeader, header)
    let finalData = { ...data }

    function deleteEmptyProperty(obj: UnknownObject): UnknownObject {
      const getNewObj: UnknownObject = cloneDeep(obj)
      Object.keys(getNewObj).forEach(key => {
        if (getNewObj[key] === null || getNewObj[key] === 'null') {
          delete getNewObj[key]
        }
        if (typeof getNewObj[key] === 'object') {
          getNewObj[key] = deleteEmptyProperty(getNewObj[key])
        }
      })
      return getNewObj
    }
    finalData = { ...deleteEmptyProperty(finalData) }

    Object.keys(finalHeader).forEach(
      key => (!finalHeader[key] || finalHeader[key] === null) && delete finalHeader[key]
    )
    const url = this.url(path)
    const promise = request<Promise<Response>>({
      url,
      method,
      headers: finalHeader,
      params: finalData,
      data: finalData,
      timeout: 10000,
      withCredentials: true
    })
    return this.unpackResponse(promise)
  }

  async unpackResponse<T>(promise: Promise<Response>): Promise<T> {
    return promise
      .then(async (res: Response) => {
        const wxStatusCode = res.status

        if (wxStatusCode < 400) {
          /**
           * err_no 说明:
           * => 1: 报错, ResponseMsg显示给用户
           * => 0: 数据返回成功
           * => -1: 报错, ResponseMsg不显示
           */
          const { err_msg, err_no } = res.data

          if (+err_no === 0) {
            return res.data.results
          }

          return { err_no, err_msg }
          // console.log("Looks like the response wasn't perfect, got status", res.status);
          // return Promise.reject(new Error(res.statusText));
        }
        return new Error(res.statusText)
      })
      .catch(err => {
        if (err.message === 'request:fail response data convert to UTF8 fail') {
          return Promise.reject(err)
        }
        return Promise.reject(err)
      })
  }
}
