import React, { Suspense } from 'react'

interface Props {
  location: any
  component: any
}

function WrappedComponent(props: Props): JSX.Element {
  const { location, component, ...others } = props
  return <Suspense fallback="">{React.createElement(component, { location, ...others })}</Suspense>
}

export default WrappedComponent
