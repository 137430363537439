declare const FMT_ENV: string
declare const FMT_NAME: string
declare const FMT_NODE_ENV: string
declare const ENABLE_ACTIVITY: string

const C_FMT_ENV = FMT_ENV
const C_FMT_NAME = FMT_NAME
const C_NODE_ENV = FMT_NODE_ENV
const C_ENABLE_ACTIVITY = ENABLE_ACTIVITY

export { C_FMT_ENV as FMT_ENV }
export { C_FMT_NAME as FMT_NAME }
export { C_NODE_ENV as FMT_NODE_ENV }
export { C_ENABLE_ACTIVITY as ENABLE_ACTIVITY }

