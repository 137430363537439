import { lazy } from 'react'

const routes = [
  {
    path: '/',
    component: lazy(() => import(/* webpackChunkName: "home" */ '../pages/home'))
  }
]

export default routes
