import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { container } from '@landrover/foundation/index'

import SAASService from '@services/saas.service'

import WrappedComponent from './WrappedComponent'
import routes from '../routes'

import './normalize.css'

container.saasService = new SAASService()

function App(): JSX.Element {
  return (
    // 如需要设置basename 必须使用config.getReactRouterBaseName
    <Router>
      <Switch>
        {routes.map(item => (
          // 循环输出Route.
          <Route key={item.path} path={item.path} exact={item.path === '/'}>
            {({ location }) => <WrappedComponent location={location} component={item.component} />}
          </Route>
        ))}
        {/* <Redirect path="/" to="/home" /> */}
      </Switch>
    </Router>
  )
}

export default App
