import container from '../platform/container'
import Storage from '../platform/storage'
import { FMT_ENV, FMT_NAME, FMT_NODE_ENV, ENABLE_ACTIVITY } from '../platform/constants'

const env = FMT_ENV
const name = FMT_NAME
const node_env = FMT_NODE_ENV

const getReactRouterBaseName = (): string => {
  if (node_env === 'production') {
    return ENABLE_ACTIVITY === 'true' ? `/activity/${name}` : `/${name}`
  }

  return ''
}

const config = {
  env,
  name,
  node_env,
  getReactRouterBaseName
}

const storage = new Storage()

const keyWithNamespacePrefix = (key: string): string => {
  if (env === 'prd') {
    return key
  }
  return `${env}_${key}`
}

const storagePrototype = Object.getPrototypeOf(storage)
const methods = Object.getOwnPropertyNames(storagePrototype)

methods.forEach(fname => {
  const original = storagePrototype[fname]

  if (typeof original === 'function') {
    if (
      fname === 'getItem' ||
      fname === 'getItemSync' ||
      fname === 'setItem' ||
      fname === 'setItemSync' ||
      fname === 'removeItem' ||
      fname === 'removeItemSync'
    ) {
      storagePrototype[fname] = (key: string, value: string) => {
        const newKey = keyWithNamespacePrefix(key)
        return original.call(storage, newKey, value)
      }
    }
  }
})

export { container, storage, config }
